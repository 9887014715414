(function ($, site) {
  Drupal.behaviors.countryChooser = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      site.util = site.util || {};
      site.util.refreshSelectBoxes = site.util.refreshSelectBoxes || function () {};

      var $menu = $('.country-select__menu .menu--lvl-1', context);
      var $links = $('.menu__link', $menu);
      var $select = $('select.country-select__selectbox', context);
      var $options = [];
      var defaultCountry = typeof site.defaultCountry !== 'undefined' ? site.defaultCountry.toLowerCase() : '';

      $links.each(function () {
        var $me = $(this),
          href = $me.attr('href'),
          text = $me.text();
        var attr = '';

        if (text.toLowerCase() === defaultCountry) {
          attr += ' selected="selected"';
        }

        $options.push('<option value="' + href + '"' + attr + '>' + text + '</option>');
      });
      $select.append($options);

      // use wrapper from scripts.js instead of "$select.selectBox('refresh');" for touch devices.
      site.util.refreshSelectBoxes($select);

      $select.change(function () {
        var loc = $(this).val();

        if (loc) {
          window.location = loc;
        }
      });

      $select.selectBox();
    },
    attached: false
  };
})(jQuery, window.site || {});
